<template>
  <div>
    <PaymentMembershipPlans
      class="mt-10"
      :is-ws-payment="true"
      :used-licenses="usedLicenses"
      :user-subscription="userSubscription" />
  </div>
</template>
<script>
import PaymentMembershipPlans from '@/components/PaymentV2/PaymentMembershipPlans';
export default {
  name: 'Payment',
  components: {
    PaymentMembershipPlans,
  },
  props: {
    plan: {
      type: Object,
      default: () => {
      },
    },
    usedLicenses: {
      type: Number,
      default: 0,
    },
    userSubscription: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
<style lang="scss" scoped>
$radius: 0.5rem;

.payment-heading {
  font-family: Poppins;
  font-size: 42px;
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    font-size: 64px;
  }
}
.payment-subheading {
  letter-spacing: initial;
  color: var(--v-black-lighten2);
}

.stripe-card-container {
  padding: 40px 32px 15px;
  margin-top: 32px;
  background: #f7fafc;
  border-radius: $radius;
  .card-errors {
    min-height: 30px;
    display: flex;
    align-items: center;
    color: green;
    font-size: 1.2rem;
  }
}
.stripe-card {
  border: 1px solid rgb(223, 223, 223);
  border-radius: $radius;
  padding: 0.8rem 1.2rem;
  background: #fff;
}
.stripe-card.complete {
  border-color: green;
}
.stripe-pay-btn {
  font-size: 1.3rem;
  background: #488d34;
  padding: 1.2rem 0;
  color: #fff;
  font-weight: 600;
  border-radius: $radius;
  width: 100%;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.payButton {
  background: linear-gradient(270deg, #CF0072 -19.12%, #E9994A 102.38%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50px !important;
  padding: 6px 36px !important;
  font-size: 14px;
}
.v-btn-toggle {
  border-radius: 5px;
  @media only screen and (max-width: 340px) {
    display: inline-grid;
    .v-btn {
      width: 152px;
      border: 0 !important;
    }
  }
}
.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border: 1px solid rgba(0, 0, 0, .12) !important;
  &--active {
    &:before {
      border-color: lightgrey !important;
      font-weight: bold;
    }
  }
}
.v-btn--active {
  background: #e8f7ff;
}
.prising {
  &__description {
    font-size: 12px;
    color: rgba(52, 52, 54, 0.7);
    margin-bottom: 0;
  }
  &__header {
    font-weight: bold;
  }
}

</style>
