import Currency from '@/constants/currency';
import { createCurrency } from '@/utils/Payment/CurrencyController';
import { mapGetters } from 'vuex';
import { isUserInCanada } from '@/utils/geolocation';
export default {
  data: () => ({
    currencyController: null,
  }),
  computed: {
    ...mapGetters('FeatureFlags', ['usePlatformPricingUpdates']),
  },
  methods: {
    async initCurrencyController(modelSettings = Currency.MODEL_SETTINGS ) {
      let currency = await isUserInCanada() ? Currency.CAD : Currency.USD;
      this.currencyController = createCurrency({
        currency,
        ...modelSettings && {
          modelSettings,
        },
      });
    },
  },
};
