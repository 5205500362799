<template>
  <v-row
    class="pb-2"
    no-gutters
    style="display: flex; flex-direction: column; align-items: center">
    <h2 class="text-center pb-4">
      Please provide your card details to complete subscription:
    </h2>
    <v-col
      class="stripe-card-container elevation-1 mb-3"
      style="border: 1px solid rgba(0, 0, 0, 0.1); max-width: 500px">
      <form
        id="payment-form"
        class="sr-payment-form">
        <div class="sr-combo-inputs-row">
          <div
            id="card-element"
            data-test="creditcard_number_input_field"
            class="sr-input sr-card-element"
            style="padding: 1rem;background: #fff;border-radius: 0.5rem;" />
        </div>
        <p
          id="card-errors"
          class="x-small-p red--text"
          role="alert">
          {{ error }}
        </p>
      </form>
      <v-radio-group
        v-model="selectedPrice"
        style="padding:10px 20px 0px 20px">
        <div
          v-for="(plan, i) in filteredSortedPrices"
          :key="i"
          class="radio-wrapper">
          <v-radio
            color="blue"
            :label="getPlanTitle(plan)"
            :value="plan.id"
            @change="selectIndex(i)" />
        </div>
        <span
          v-if="true || eligibleForTrial"
          class="mt-2">
          (30-day free trial will be active!)
        </span>
        <span
          v-if="selectedPrice"
          class="mt-2">
          Subscription will be renewed on {{ availablePlanDate }}
        </span>
      </v-radio-group>
      <v-row
        no-gutters
        justify="center"
        style="padding-bottom: 20px;">
        <v-btn
          :loading="loading"
          data-test="pay_button"
          color="blue"
          class="payButton elevation-0 white--text"
          @click="pay(selectedPrice)">
          Pay
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import PaymentsApi from '@/services/graphql/payments';
import {
  mapActions, mapGetters, mapMutations,
} from 'vuex';
import currencyController from '@/mixins/currencyController';
export default {
  name: 'PaymentsCard',
  mixins: [currencyController],
  props: {
    sortedPrices: {
      type: Array,
      default: () => [],
    },
    userSubscription: {
      type: Object,
      default: () => null,
    },
    eligibleForTrial: {
      type: Boolean,
      default: () => false,
    },
    radioRangeBlock: {
      type: Boolean,
      default: () => false,
    },
    usedLicenses: {
      type: Number,
      default: () => 0,
    },
    selectedPlan: {
      type: Number,
      default: 0,
    },
    isWorkspacePaymentsCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedPrice: null,
      error: '',
      loading: false,
      indexPlan: null,
      stripeDataTest: [
        {
          name: 'creditcard_number_input_field',
          inputName: 'cardnumber',
        },
        {
          name: 'creditcard_date_input_field',
          inputName: 'cardExpiry',
        },
        {
          name: 'creditcard_cvc_input_field',
          inputName: 'cvc',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('Payment', ['getPreSelectedRole']),
    findPreSelectedRole() {
      const preSelectedRole = this.getPreSelectedRole;
      return (
        preSelectedRole &&
        this.filteredSortedPrices.find(
          e =>
            e?.nickname.includes(preSelectedRole?.role) &&
            e?.recurring.interval === preSelectedRole?.interval
        )?.id
      );
    },
    filteredSortedPrices() {
      if (this.sortedPrices && this.sortedPrices.length > 0) {
        return this.sortedPrices.filter(plan => this.isFullObject(plan));
      } else {
        return [];
      }
    },
    availablePlanDate() {
      const yearOrMonth = this.activePlan?.recurring?.interval === 'year';
      const today = this.$moment().format('YYYY-MM-DD');
      const currentDate = this.$moment(today);
      if (yearOrMonth) {
        return this.$moment(currentDate)
          .add(1, 'Y')
          .format('DD-MM-YYYY');
      }
      return this.$moment(currentDate)
        .add(1, 'M')
        .format('DD-MM-YYYY');
    },
    activePlan() {
      return this.sortedPrices.find(el => el.id === this.selectedPrice);
    },
  },
  watch: {
    findPreSelectedRole(id) {
      if (id) {
        this.selectedPrice = id;
      }
    },
    selectedPlan() {
      const { sortedPrices, indexPlan } = this;
      if (indexPlan !== null) {
        this.selectedPrice = sortedPrices[indexPlan].id;
      }
    },
  },
  created() {
    //if we set view in vuex before , then we have view on back and need remove the view
    this.getPreSelectedRole && this.removeViewParam();
  },
  async mounted() {
    await this.initCurrencyController();
    this.setupElements();
  },
  methods: {
    ...mapActions({
      handleError: 'handleError',
    }),
    ...mapMutations(['openSnackBar']),
    async removeViewParam() {
      try {
        await PaymentsApi.deleteViewParams({
          viewId: 'payment',
        });
      } catch (err) {
        this.handleError(err);
      }
    },
    selectIndex(i) {
      this.indexPlan = i;
    },
    isFullObject(plan) {
      return plan && Object.values(plan).every(item => item);
    },
    async pay(selectedPrice) {
      if (this.stripe && this.card && selectedPrice) {
        this.loading = true;
        this.error = '';
        try {
          const result = await this.stripe.createPaymentMethod({
            type: 'card',
            card: this.card,
          });
          if (result.error) {
            this.error = result.error.message;
            this.loading = false;
          } else {
            this.saveAndAttachPaymentMethodId(
              result.paymentMethod.id,
              selectedPrice,
            );
          }
        } catch (err) {
          this.error = err;
          this.loading = false;
          console.log('err pay', err);
          this.openSnackBar({
            text: 'Error making payment. Please try again. If failure persists, please contact support',
          });
        }
      }
    },
    async saveAndAttachPaymentMethodId(paymentMethodId, selectedPrice) {
      try {
        const { currency = '' } = this.currencyController || {
        };
        const resp = await PaymentsApi.saveAndAttachPaymentMethodId({
          paymentMethodId,
          currency,
        });
        if (!resp.data.response.success === true) {
          throw new Error(resp.data.response.message);
        }
        this.$emit('paymentModel', false);
        this.$emit(
          'stripeCreateWorkspaceSubscribe',
          selectedPrice,
          paymentMethodId,
          currency,
        );
      } catch (err) {
        this.loading = false;
        if (err.errors) {
          const error = err.errors[0]?.message;
          if (error.includes('Your card has insufficient funds')) {
            this.error = 'Your card has insufficient funds';
          } else {
            this.error = err.errors[0]?.message;
          }
        } else {
          console.error('Error saving and attaching payment method id', err);
        }
      } finally {
        this.loading = false;
      }
    },
    getPlanTitle({ recurring, unit_amount = 1, id = '', nickname } = {
    }) {
      if (!this.currencyController) {
        console.error('Unable to get plan title because currencyController is missing!');
        return '';
      }
      let title = nickname;
      const price = this.currencyController.formatCurrency(unit_amount / 100);
      const { interval } = recurring ?? {
      };
      let planTitle = `${title} - ${price} / ${interval}`;
      if (interval === 'year') {
        const pricePerMonth = this.currencyController.formatCurrency(+unit_amount / 12 / 100);
        planTitle += ` (${pricePerMonth} / month)`;
      }
      const { priceId = '' } = this.userSubscription ?? {
      };
      if (priceId === id) {
        planTitle += ' (Your current subscription)';
      }
      return planTitle;
    },
    setupElements() {
      // const stripe = window.Stripe('pk_test_51Hvg8oHTOrfLh7WUcFpVn6kXzpRWIVdVe0fYw1Q6c5XDR6NmTDC6liWwS8SzzZaKShUDqAr5pUerQisxH0QZmXJN00myTqtEnU');
      const stripe = window.Stripe(process.env.VUE_APP_STRIPE_API_KEY);

      /* ------- Set up Stripe Elements to use in checkout form ------- */
      const elements = stripe.elements();
      const style = {
        base: {
          color: '#8CA3A9',
          fontFamily: '"Roboto", sans-serif',
          backgroundColor: '#fff',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };
      const card = elements.create('card', {
        style,
      });
      card.mount('#card-element');
      this.stripe = stripe;
      this.card = card;
      card.on('change', e => {
        if (e.error) {
          this.error = e.error.message;
        } else {
          this.error = '';
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
