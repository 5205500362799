<template>
  <v-col
    class="page-container"
    align="center">
    <v-row
      v-if="isFetchingPlans"
      class="pricing-container justify-center"
      style="max-width: 1200px">
      <v-col
        v-for="n in 3"
        :key="n"
        md="4"
        cols="12">
        <div class="pricing-container__item skeleton-top-border">
          <v-skeleton-loader
            height="30"
            class="my-4"
            type="heading" />
          <v-skeleton-loader
            class="px-6"
            type="list-item-three-line" />
        </div>
      </v-col>
    </v-row>
    <v-row
      v-else
      class="pricing-container justify-center"
      style="max-width: 1200px">
      <v-col
        v-for="(item, i) of plans"
        :key="i"
        md="4"
        cols="12">
        <div
          class="pricing-container__item"
          :style="{ borderColor: item.color }">
          <header>
            <h4 class="font-weight-regular mb-1 plan-title">
              {{ item.title }}
            </h4>
            <h5
              v-for="(plan, p) in item.plans"
              :key="i + p"
              class="font-weight-regular text-center"
              style="font-size: 26px">
              <strong style="font-size: 30px">
                {{ plan.amount }}
              </strong>
              <span class="currency-text">
                {{ plan.interval ? currency : '' }}
              </span>
              {{ plan.period || '' }}
            </h5>
          </header>
          <main>
            <ul v-if="Array.isArray(item.text)">
              <li
                v-for="(textItem, t) in item.text"
                :key="i + t">
                {{ textItem }}
              </li>
            </ul>
            <div class="text-center mt-5">
              <v-btn
                v-if="item.title == 'Enterprise'"
                color="red"
                class="white--text"
                data-test="upgrade_workspace_category_button"
                elevation="0"
                dark
                @click="redirectToHelp">
                contact Us
              </v-btn>
              <app-dialog
                :key="reloadPaymentModal"
                v-model="showPaymentModal"
                width="500"
                content-class="v-dialog__form">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="item.title == 'Premium'"
                    color="red"
                    class="white--text"
                    data-test="upgrade_workspace_category_button"
                    elevation="0"
                    dark
                    v-on="on">
                    Buy Now
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="align-center">
                    <span>Please provide your card details</span>
                    <span
                      class="sourcery__icon-wrapper black"
                      @click="closePaymentModal">
                      <v-icon
                        color="white"
                        size="15">
                        mdi-close
                      </v-icon>
                    </span>
                  </v-card-title>
                  <v-card-text class="card-text">
                    <v-form ref="workspaceEditorForm">
                      <!-- User subscription is bugged for new WS -->
                      <PaymentsCard
                        :is-workspace-payments-card="isWsPayment"
                        :user-subscription="{}"
                        :sorted-prices="sortedPrices"
                        @stripeCreateWorkspaceSubscribe="stripeCreateWorkspaceSubscribe"
                        @changeSubscriptionPlan="changeSubscriptionPlan"
                        @stripeCreateSubscribe="stripeCreateSubscribe"
                        @paymentModel="paymentModel"
                        v-on="$listeners" />
                    </v-form>
                  </v-card-text>
                </v-card>
              </app-dialog>
            </div>
          </main>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import dataJson from '@/dataFront/data';
import PaymentsApi from '@/services/graphql/payments';
import WorkspacePaymentApi from '@/services/graphql/workspacePayment';
import PaymentsCard from '@/components/Payment/PaymentsCard';
import currencyController from '@/mixins/currencyController';
import Currency from '@/constants/currency';
import { HELP_CENTER } from '@/constants/urls';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
export default {
  name: 'PaymentMembershipPlans',
  components: {
    PaymentsCard,
  },
  mixins: [currencyController],
  props: {
    isWsPayment: {
      type: Boolean,
      default: false,
    },
    usedLicenses: {
      type: Number,
      default: 0,
    },
    userSubscription: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // These are from data.json (mapped for ease).
      // Actual plans are computed (updated/hydrated from data.json).
      selectedPlan: {
      },
      plansTypes: [],
      plansFromBack: [],

      isFetchingPlans: true,
      priceList: {
      },
      showPaymentModal: false,
      reloadPaymentModal: 0,
    };
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('Payment', ['getPreSelectedTypePlan', 'getPreSelectedRole']),
    ...mapGetters({
      usePlatformPricingUpdates: 'FeatureFlags/usePlatformPricingUpdates',
    }),
    getSelectedPlan() {
      return this.isWsPayment ? dataJson.wsPayment : dataJson.mainPayment;
    },
    sortedPrices() {
      let sortedPrices = [];
      for (const key in this.priceList) {
        sortedPrices = sortedPrices.concat(this.priceList[key]);
      }
      sortedPrices.sort((a, b) => {
        return parseInt(b.unit_amount) - parseInt(a.unit_amount);
      });
      sortedPrices.map((price) => {
        return {
          ...price,
          nickname: price.nickname ?? 'Workspace License',
        };
      });
      return sortedPrices;
    },
    plans() {
      const rawPlansData = this.getSelectedPlan.plans;

      const hydratedPlans = rawPlansData.map((plan) => {
        // Overwrite the prices based on fetched Stripe API Data.
        if (plan.title === 'Premium') {
          const priceData = this.priceList['workspace_premium'];
          if (!priceData) return plan;

          const monthPrice = priceData.find((price) => price.recurring.interval === 'month').unit_amount;
          const yearPrice = priceData.find((price) => price.recurring.interval === 'year').unit_amount;

          const updatedPlans = plan.plans.map((p) => {
            const updatedAmount = p.interval === 'month' ? monthPrice : yearPrice;
            return {
              ...p,
              amount: this.$formatCurrency(updatedAmount / 100),
            };
          });

          return {
            ...plan,
            plans: updatedPlans,
          };
        }

        return plan;
      });

      return hydratedPlans;
    },
    currency() {
      return this.currencyController?.currency ? this.currencyController.currency.toUpperCase() : '';
    },
  },
  async created() {
    this.spinner(true);
    await this.initCurrencyController();
    await this.initDataPlan();
    await this.getListPrice();
    this.spinner(false);
  },
  destroyed() {
    this.clearParamsFromCustomView();
  },
  methods: {
    ...mapMutations(['spinner', 'clearParamsFromCustomView']),
    ...mapActions(['handleError']),
    ...mapActions('Payment', [
      'paymentIsProcessingNotification',
    ]),
    redirectToHelp() {
      window.open(HELP_CENTER, '_blank');
    },
    closePaymentModal() {
      this.showPaymentModal = false;
      this.reloadPaymentModal++;
    },
    initDataPlan() {
      const plan = this.getSelectedPlan;
      this.plansTypes = plan.plansTypes;
      this.plansFromBack = plan.plansFromBack;
    },
    async getListPrice() {
      this.isFetchingPlans = true;
      try {
        const { currency = '' } = this.currencyController || {
        };
        await Promise.all(
          this.plansFromBack.map(async filter => {
            const { data } = await PaymentsApi.getListPrice({
              filter: filter,
              currency,
            });
            this.$set(this.priceList, filter, data.response);
          })
        );
      } catch (err) {
        console.error(err);
      } finally {
        this.isFetchingPlans = false;
      }
    },
    async paymentModel(value) {
      this.showPaymentModal = value;
      this.reloadPaymentModal++;
    },

    /**
     * Stripe Callbacks below.
     */
    async stripeCreateWorkspaceSubscribe(
      selectedPrice,
      quantity,
      paymentMethodId,
      currency = Currency.CAD
    ) {
      try {
        this.spinner(true);
        await WorkspacePaymentApi.stripeCreateWorkspaceSubscribe({
          priceId: selectedPrice,
          workspaceId: this.activeWorkspaceId,
          quantity,
          paymentMethodId,
          currency,
        });
        this.paymentIsProcessingNotification();
        this.$router.push({
          name: 'projects',
        });
      } catch (err) {
        this.handleError(err);
        console.error(err);
      } finally {
        this.spinner(false);
      }
    },
    async stripeCreateSubscribe(selectedPrice, currency) {
      try {
        this.spinner(true);
        await PaymentsApi.stripeCreateSubscribe({
          priceId: selectedPrice,
          currency,
        });
        this.paymentIsProcessingNotification();
        if (this.getPreSelectedRole) {
          await this.$router.push({
            name: 'workspaces',
          });
        } else {
          await this.$router.push({
            name: 'projects',
          });
        }
      } catch (err) {
        this.handleError(err);
        console.error(err);
      } finally {
        this.spinner(false);
      }
    },
    async changeSubscriptionPlan(priceId) {
      try {
        this.spinner(true);
        await PaymentsApi.changeSubscriptionPlan({
          subId: this.userSubscription?.sub_id,
          priceId,
        });
        this.paymentIsProcessingNotification();
        await this.$router.push({
          name: 'projects',
        });
      } catch (err) {
        this.handleError('You can\'t change workspace subscription to lower plan');
        console.error(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pricing-container {
  justify-content: space-between;
  @media only screen and (max-width: 903px) {
    justify-content: center;
  }
  &__item {
    max-width: 422px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-top: 7px solid;
    margin-bottom: 32px;
    padding-bottom: 36px;
    header {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      padding: 16px 0;
      .plan-title {
        font-size: 34px;
        margin-bottom: 10px;
        line-height: normal;
      }
      h4 span {
        width: 220px;
        margin: auto;
        display: flex;
      }
    }
    main {
      text-align: left;
      padding: 15px 30px 0;
      text-align: left;
      p {
        margin: auto;
      }
      ul {
        padding-left: 20px;
      }
    }
  }
}
.pricing {
  &__manufacturer-link {
    color: var(--v-blue-base) !important;
    font-size: 18px;
  }
}
.card-text {
  background-color: #fff;
}
.skeleton-top-border {
  border-color: var(--v-lightGrey-base);
}
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__heading {
    height: 100%;
  }
  .v-skeleton-loader__list-item-three-line {
    text-align: left;
  }
}
.currency-text {
  font-size: 1.125rem;
  margin-left: -0.25rem;
}
</style>
