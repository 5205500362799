<template>
  <v-row
    class="pb-2"
    no-gutters
    style="display: flex; flex-direction: column; align-items: center">
    <h2 class="text-center pb-4">
      Please provide your card details to complete subscription:
    </h2>
    <v-col
      class="stripe-card-container elevation-1 mb-3"
      style="border: 1px solid rgba(0, 0, 0, 0.1); max-width: 500px">
      <form
        id="payment-form"
        class="sr-payment-form">
        <div class="sr-combo-inputs-row">
          <div
            id="card-element"
            data-test="creditcard_number_input_field"
            class="sr-input sr-card-element"
            style="padding: 1rem;background: #fff;border-radius: 0.5rem;" />
        </div>
        <p
          id="card-errors"
          class="x-small-p red--text"
          role="alert">
          {{ error }}
        </p>
      </form>
      <v-radio-group
        v-model="selectedPrice"
        style="padding:10px 20px 0px 20px">
        <div
          v-for="(plan, i) in filteredSortedPrices"
          :key="i"
          class="radio-wrapper">
          <v-radio
            color="blue"
            :data-test="getDataTestAttr(plan)"
            :label="getPlanTitle(plan)"
            :value="plan.id"
            @change="selectIndex(i)" />
        </div>
        <div
          v-if="selectedPrice && radioRangeBlock"
          class="range-wrapper mt-4">
          <span>For {{ usersCount }} users you will pay {{ costForAllUsers }}</span>
          <v-slider
            :key="sliderKey"
            v-model="usersCount"
            data-test="workspace_license_slider"
            class="mt-0"
            :min="1"
            :max="100"
            @input="catchInput" />
          <span>This plan will be available till {{ availablePlanDate }}</span>
        </div>
      </v-radio-group>
      <v-row
        no-gutters
        justify="center"
        style="padding-bottom: 20px;">
        <v-btn
          :loading="loading"
          data-test="pay_button"
          color="blue"
          class="payButton elevation-0 white--text"
          @click="pay(selectedPrice, usersCount)">
          Pay
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import PaymentsApi from '@/services/graphql/payments';
import {
  mapActions, mapGetters, mapMutations,
} from 'vuex';
import currencyController from '@/mixins/currencyController';
export default {
  name: 'PaymentsCard',
  mixins: [currencyController],
  props: {
    sortedPrices: {
      type: Array,
      default: () => [],
    },
    userSubscription: {
      type: Object,
      default: () => null,
    },
    radioRangeBlock: {
      type: Boolean,
      default: () => false,
    },
    usedLicenses: {
      type: Number,
      default: () => 0,
    },
    selectedPlan: {
      type: Number,
      default: 0,
    },
    isWorkspacePaymentsCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      usersCount: 25,
      selectedPrice: null,
      error: '',
      loading: false,
      sliderKey: 0,
      indexPlan: null,
      stripeDataTest: [
        {
          name: 'creditcard_number_input_field',
          inputName: 'cardnumber',
        },
        {
          name: 'creditcard_date_input_field',
          inputName: 'cardExpiry',
        },
        {
          name: 'creditcard_cvc_input_field',
          inputName: 'cvc',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('Payment', ['getPreSelectedRole']),
    isWsPayment() {
      return this.$route.name === 'workspace-payment';
    },
    findPreSelectedRole() {
      const preSelectedRole = this.getPreSelectedRole;
      return (
        preSelectedRole &&
        this.filteredSortedPrices.find(
          e =>
            e?.nickname.includes(preSelectedRole?.role) &&
            e?.recurring.interval === preSelectedRole?.interval
        )?.id
      );
    },
    filteredSortedPrices() {
      if (this.sortedPrices && this.sortedPrices.length > 0) {
        this.sortedPrices.forEach(plan => {
          plan.nickname = 'Workspace License';
        });
        return this.sortedPrices.filter(plan => this.isFullObject(plan));
      } else {
        return [];
      }
    },
    availablePlanDate() {
      const yearOrMonth = this.activePlan?.recurring?.interval === 'year';
      const today = this.$moment().format('YYYY-MM-DD');
      const currentDate = this.$moment(today);
      if (yearOrMonth) {
        return this.$moment(currentDate)
          .add(1, 'Y')
          .format('DD-MM-YYYY');
      }
      return this.$moment(currentDate)
        .add(1, 'M')
        .format('DD-MM-YYYY');
    },
    costForAllUsers() {
      return this.$formatCurrency((this?.activePlan?.unit_amount / 100) * this.usersCount);
    },
    activePlan() {
      return this.sortedPrices.find(el => el.id === this.selectedPrice);
    },
  },
  watch: {
    findPreSelectedRole(id) {
      if (id) {
        this.selectedPrice = id;
      }
    },
    selectedPlan() {
      const { sortedPrices, indexPlan } = this;
      if (indexPlan !== null) {
        this.selectedPrice = sortedPrices[indexPlan].id;
      }
    },
  },
  created() {
    //if we set view in vuex before , then we have view on back and need remove the view
    this.getPreSelectedRole && this.removeViewParam();
  },
  async mounted() {
    await this.initCurrencyController();
    this.setupElements();
  },
  methods: {
    ...mapActions({
      handleError: 'handleError',
    }),
    ...mapMutations(['openSnackBar']),
    async removeViewParam() {
      try {
        await PaymentsApi.deleteViewParams({
          viewId: 'payment',
        });
      } catch (err) {
        this.handleError(err);
      }
    },
    selectIndex(i) {
      this.indexPlan = i;
    },
    isFullObject(plan) {
      return plan && Object.values(plan).every(item => item);
    },
    async pay(selectedPrice, subscriptionsCount) {
      if (this.stripe && this.card && selectedPrice) {
        this.loading = true;
        this.error = '';
        try {
          const result = await this.stripe.createPaymentMethod({
            type: 'card',
            card: this.card,
          });
          if (result.error) {
            this.error = result.error.message;
            this.loading = false;
          } else {
            this.saveAndAttachPaymentMethodId(
              result.paymentMethod.id,
              selectedPrice,
              subscriptionsCount
            );
          }
        } catch (err) {
          this.error = err;
          this.loading = false;
          console.log('err pay', err);
        }
      }
    },
    async saveAndAttachPaymentMethodId(paymentMethodId, selectedPrice, quantity) {
      try {
        const { currency = '' } = this.currencyController || {
        };
        await PaymentsApi.saveAndAttachPaymentMethodId({
          paymentMethodId,
          currency,
        });
        this.$emit('paymentModel', false);
        const { isWsPayment } = this;
        // якшо вибирають то не кидати запит
        if (isWsPayment) {
          this.$emit(
            'stripeCreateWorkspaceSubscribe',
            selectedPrice,
            quantity,
            paymentMethodId,
            currency,
          );
        } else {
          if (this.userSubscription) {
            this.$emit('changeSubscriptionPlan', selectedPrice);
          } else {
            this.$emit('stripeCreateSubscribe', selectedPrice, currency);
          }
        }
      } catch (err) {
        this.loading = false;
        const error = err.errors[0]?.message;
        if (error.includes('Your card has insufficient funds')) {
          this.error = 'Your card has insufficient funds';
        } else {
          this.error = err.errors[0]?.message;
        }
      } finally {
        this.loading = false;
      }
    },
    getBaseTitle({ nickname = '' } = {
    }) {
      if (nickname.includes('Premium')) {
        return 'Pro';
      }
      if (nickname.includes('Design Partner')) {
        return 'Collaborator';
      }
      if (nickname.includes('Workspace License') || nickname.includes('Workspace Agency License')) {
        return 'Premium';
      }
      return nickname;
    },
    getDataTestAttr(plan) {
      if (this.isWorkspacePaymentsCard) {
        return `workspace_${this.getBaseTitle(plan).toLowerCase()}_${
          plan.recurring.interval
        }ly_radiobutton`;
      }
      return `${this.getBaseTitle(plan).toLowerCase()}_plan_${
        plan.recurring.interval
      }ly_radiobutton`;
    },
    getPlanTitle({ recurring, unit_amount = 1, id = '', nickname } = {
    }) {
      if (!this.currencyController) return '';
      let title = this.getBaseTitle({
        nickname,
      });
      const price = this.currencyController.formatCurrency(unit_amount / 100);
      const { interval } = recurring ?? {
      };
      let planTitle = `${title} - ${price} / ${interval}`;
      if (interval === 'year') {
        const pricePerMonth = this.currencyController.formatCurrency(+unit_amount / 12 / 100);
        planTitle += ` (${pricePerMonth} / month)`;
      }
      const { priceId = '' } = this.userSubscription ?? {
      };
      if (priceId === id) {
        planTitle += ' (Your current subscription)';
      }
      return planTitle;
    },
    setupElements() {
      // const stripe = window.Stripe('pk_test_51Hvg8oHTOrfLh7WUcFpVn6kXzpRWIVdVe0fYw1Q6c5XDR6NmTDC6liWwS8SzzZaKShUDqAr5pUerQisxH0QZmXJN00myTqtEnU');
      const stripe = window.Stripe(process.env.VUE_APP_STRIPE_API_KEY);

      /* ------- Set up Stripe Elements to use in checkout form ------- */
      const elements = stripe.elements();
      const style = {
        base: {
          color: '#8CA3A9',
          fontFamily: '"Roboto", sans-serif',
          backgroundColor: '#fff',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };
      const card = elements.create('card', {
        style,
      });
      card.mount('#card-element');
      this.stripe = stripe;
      this.card = card;
      // const domElement = card._component;
      // // eslint-disable-next-line no-unused-vars
      // this.stripeDataTest.forEach(({ name, inputName }) => {
      //   console.log(domElement.querySelector(`input[name=${inputName}]`));
      // });
      // console.log(domElement);
      card.on('change', e => {
        if (e.error) {
          this.error = e.error.message;
        } else {
          this.error = '';
        }
      });
    },
    catchInput(e) {
      if (e < this.usedLicenses) {
        this.usersCount = this.usedLicenses;
        this.sliderKey++;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
