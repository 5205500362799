var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"page-container",attrs:{"align":"center"}},[(_vm.isFetchingPlans)?_c('v-row',{staticClass:"pricing-container justify-center",staticStyle:{"max-width":"1200px"}},_vm._l((3),function(n){return _c('v-col',{key:n,attrs:{"md":"4","cols":"12"}},[_c('div',{staticClass:"pricing-container__item skeleton-top-border py-4  "},[_c('v-skeleton-loader',{staticClass:"my-4",attrs:{"height":"30","type":"heading"}}),_c('v-skeleton-loader',{staticClass:"px-6",attrs:{"type":"list-item-three-line"}})],1)])}),1):_c('v-row',{staticClass:"pricing-container justify-center",staticStyle:{"max-width":"1200px"}},_vm._l((_vm.plans),function(item,i){return _c('v-col',{key:i,attrs:{"md":"4","cols":"12"}},[_c('div',{staticClass:"pricing-container__item mr-4",class:{
          'pricing-container__item--border': item.style === 'border',
          'pricing-container__item--shadow': item.style === 'shadow'
        }},[_c('header',{staticClass:"pt-10"},[(item.revitIntegrated)?_c('div',{staticClass:"pricing-container__revit-integrated"},[_c('img',{staticClass:"mr-2",attrs:{"src":require('@/assets/icons/revit-2.png'),"width":"24","height":"24"}}),_c('div',[_vm._v("REVIT INTEGRATED")])]):_vm._e(),_c('h4',{staticClass:"pricing-container__item--title"},[_vm._v(" "+_vm._s(item.title)+" ")]),(item.prePlanText)?_c('div',{staticClass:"pricing-container__item--subtext "},[_vm._v(" "+_vm._s(item.prePlanText)+" ")]):_vm._e(),_vm._l((item.plans),function(plan,p){return _c('h5',{key:i + p,staticClass:"text-center mb-1",staticStyle:{"font-size":"24px"}},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(plan.amount + plan.period || '')+" ")])])}),(item.featuresTitle)?_c('div',{staticClass:"pricing-container__item--subtext"},[_vm._v(" "+_vm._s(item.featuresTitle)+" ")]):_vm._e(),_c('div',{staticClass:"text-center mt-4"},[_c('app-dialog',{key:_vm.reloadPaymentModal,attrs:{"width":"500","content-class":"v-dialog__form"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-btn',_vm._g({staticClass:"pricing-button pricing-button__black",attrs:{"color":hover ? 'red' : 'black',"disabled":_vm.isActivePlan(item.type),"data-test":"upgrade_workspace_category_button","elevation":"0"},on:{"click":function($event){return _vm.handleClick(item.type, on)}}},_vm.isDowngradePlan(item.type) ? {} : on),[_vm._v(" "+_vm._s(_vm.planButtonText(item.type))+" ")])]}}],null,true)})]}}],null,true),model:{value:(_vm.showPaymentModal),callback:function ($$v) {_vm.showPaymentModal=$$v},expression:"showPaymentModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"align-center"},[_c('span',[_vm._v("Please provide your card details")]),_c('span',{staticClass:"sourcery__icon-wrapper black",on:{"click":_vm.closePaymentModal}},[_c('v-icon',{attrs:{"color":"white","size":"15"}},[_vm._v(" mdi-close ")])],1)]),_c('v-card-text',{staticClass:"card-text"},[_c('v-form',{ref:"workspaceEditorForm",refInFor:true},[_c('PaymentsCard',_vm._g({attrs:{"is-workspace-payments-card":_vm.isWsPayment,"eligible-for-trial":_vm.eligibleForTrial,"user-subscription":{},"sorted-prices":_vm.sortedPricesOfType(item.type)},on:{"stripeCreateWorkspaceSubscribe":_vm.stripeCreateWorkspaceSubscribe,"changeSubscriptionPlan":_vm.changeSubscriptionPlan,"stripeCreateSubscribe":_vm.stripeCreateSubscribe,"paymentModel":_vm.paymentModel}},_vm.$listeners))],1)],1)],1)],1)],1),(item.trial && _vm.eligibleForTrial)?_c('div',{staticClass:"my-2 pricing-container__item--subtext"},[_vm._v(" "+_vm._s(item.trial)+" ")]):_vm._e()],2),_c('main',{staticClass:"py-8"},[(Array.isArray(item.text))?_c('ul',{staticClass:"px-0"},_vm._l((item.text),function(textItem,t){return _c('li',{key:i + t,staticClass:"pricing-bullet__item mb-4"},[_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/icons/check-circle.svg'),"max-width":"24","max-height":"24"}}),_vm._v(" "+_vm._s(textItem)+" ")],1)}),0):_vm._e()])])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }